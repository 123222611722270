.paintSingleCard{
  max-height:50vh;
  max-width:70vw;
  pointer-events: none;
}
.cardena{
  margin-top: 15px;
  margin-bottom: 15px;
}
.cardena > div {
    position: relative;
    background: white;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: box-shadow 0.5s, opacity 0.5s;
    will-change: transform;
    border: 5px solid white;
    cursor: grab;
    overflow: hidden;
    touch-action: none;
  }
  
  .cardena > div > div {
    will-change: transform;
    height: 100%;
    margin: 0vw 0;
  }
  
  .cardena > div > div > * {
    height: 100%;
    background-size: cover;
    background-position: center center;
    margin: 0vw 0;
  }
  
  .cardena > div.dragging {
    cursor: grabbing;
  }
  
  .cardena > div.disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  .cardena > div:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  
  button {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  