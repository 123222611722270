.contAb{
    position: fixed;
    width: 100vw;
    height: 100%;
    left: 0px;
    top: 2000px;
    background: gold;
    transition: all 0.5s ease-in-out 0s;
    z-index: 11;
    padding: 0px 0px;
}
.contAb.open{
    top: 0px;
}
.About{
    box-shadow: none;
    
    flex-direction: column;
    align-items: center;
    overflow: auto;
    background: gold;
    margin: 0 30px;
    height: 100%;
}
.About h1{
    margin: 0px;
}
.About .headAbout{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin-top: 50px;
}
.About .headAbout p{
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 500;
}
.About .aboutRow{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}
.About .sectionTitle{
    margin: 0 5px;
    text-align: right;
    flex: 1 1 0%;
}
.About .sectionContent{

    margin: 0 5px;
    text-align: left;
    flex: 1.5 1 0%;
}
.About .sectionContent p {
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 500;
    margin: 10px; 
}
.About .sectionContent p a{
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 700;
    color: black;
}
.About .sectionTitle h2{
    color: white;
    font-family: 'Source Code Pro', monospace;
    text-decoration: none;
    font-weight: 700;
}
.About .socialIcons{
    margin: 0.83em 0;
}
.About .socialIcons  img{
    width: 20px;
    height: auto;
    margin: 14px 14px 14px 0;
}
@media only screen and (max-width: 1200px) and (-webkit-min-device-pixel-ratio: 3),(max-width: 1200px) and (-webkit-min-device-pixel-ratio: 2), (max-width: 1000px) {
   .contAb{
    padding: 50px 0px;
   }
    .About{
        height: 100%;
    }
    .About .headAbout {
        align-items: flex-start;
        flex-direction: column;
    }
    .About .aboutRow {
        align-items: flex-start;
        flex-direction: column;

    }
    .About .socialIcons img {
        width: 20px;
        height: auto;
        margin: 0 14px 14px 0;
    }
    .About .socialIcons {
        margin: 0.83em 0;
        margin-bottom: 5em;
    }
  }
  