.Netread .sandbox{
    background: aquamarine;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: box-shadow 0.5s, opacity 0.5s;
}
.Netread .projectRow{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 15px 0;
}
.Netread .projectRow p{
   text-align: left;
   font-family: 'Source Code Pro', monospace;
   color: black;
}
.Netread .projectRow img{
    width: 15vw;
    margin: 15px;
}
.Netread .projectRowfull{
    margin: 15px 0;
}
.Netread .projectRow h2{
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 700;
}
.Netread .projectTags{
    font-family: 'Source Code Pro', monospace;
    color: black;
    text-decoration: none;
    font-weight: 400;
}

.Netread  .tryme{
    position: relative;
    bottom: -35px;
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 500;
}
.Netread .twoColumns{
	display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}
.Netread .threeColumns{
    display: flex;
    width: 50%;
    align-items: flex-start;
    justify-content: space-evenly;
}
.Netread .twoColP{
    display: flex;
    width: 50%;
    align-items: flex-start;
    justify-content: space-evenly;
}
.Netread .prodThree{
    display: flex;
}
@media only screen and (max-width: 1200px) and (-webkit-min-device-pixel-ratio: 3),(max-width: 1000px){
    .Netread .twoColumns {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }
    .Netread .threeColumns{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
        flex-direction: column;
    }
    .Netread .twoColP{
        display: flex;
        width: 70%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .Netread .prodThree {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
    }
    .Netread .projectRow p {
        text-align: left;
        font-family: 'Source Code Pro', monospace;
        color: black;
        width: 70%!important;
    }
    .Netread .projectRow img {
        width: 70%!important;
    }
  }
  