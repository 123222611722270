.Lab{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Lab .head{
    flex: 1;
}
.Lab .wrap{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.Lab .wrap .item{
    padding: 10px;
    margin:5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Lab .item span {
    font-family: monospace;
}
.folder{
    margin: 5px;
    cursor: pointer;
}
.folder .aaa{
    background-color: black;
    height: 25px;
    width: 40px;
    border-radius: 10px;
    position: relative;
    top: 15px;

}
.folder .bbb{
    background-color: black;
    height: 60px;
    width: 80px;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: .3s all ease-in-out;
}
.folder .bbb.hover{
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}