@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400;1,500;1,600;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&family=Source+Code+Pro:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400;1,500;1,600;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: 'Source Code Pro', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: 'Source Code Pro', monospace;
}
/*Fonts*/
.link {
  font-family: 'Source Code Pro', monospace;
  color: black;
  font-weight: 700;
  text-decoration: underline;
}
h1,h2{
  font-family: 'Source Code Pro', monospace;
  color: black;
  text-decoration: none;
  font-weight: 400;
}
span{
  font-family: 'Source Code Pro', monospace;
}
.contAb{
    position: fixed;
    width: 100vw;
    height: 100%;
    left: 0px;
    top: 2000px;
    background: gold;
    transition: all 0.5s ease-in-out 0s;
    z-index: 11;
    padding: 0px 0px;
}
.contAb.open{
    top: 0px;
}
.About{
    box-shadow: none;
    
    flex-direction: column;
    align-items: center;
    overflow: auto;
    background: gold;
    margin: 0 30px;
    height: 100%;
}
.About h1{
    margin: 0px;
}
.About .headAbout{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin-top: 50px;
}
.About .headAbout p{
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 500;
}
.About .aboutRow{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}
.About .sectionTitle{
    margin: 0 5px;
    text-align: right;
    flex: 1 1;
}
.About .sectionContent{

    margin: 0 5px;
    text-align: left;
    flex: 1.5 1;
}
.About .sectionContent p {
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 500;
    margin: 10px; 
}
.About .sectionContent p a{
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 700;
    color: black;
}
.About .sectionTitle h2{
    color: white;
    font-family: 'Source Code Pro', monospace;
    text-decoration: none;
    font-weight: 700;
}
.About .socialIcons{
    margin: 0.83em 0;
}
.About .socialIcons  img{
    width: 20px;
    height: auto;
    margin: 14px 14px 14px 0;
}
@media only screen and (max-width: 1200px) and (-webkit-min-device-pixel-ratio: 3),(max-width: 1200px) and (-webkit-min-device-pixel-ratio: 2), (max-width: 1000px) {
   .contAb{
    padding: 50px 0px;
   }
    .About{
        height: 100%;
    }
    .About .headAbout {
        align-items: flex-start;
        flex-direction: column;
    }
    .About .aboutRow {
        align-items: flex-start;
        flex-direction: column;

    }
    .About .socialIcons img {
        width: 20px;
        height: auto;
        margin: 0 14px 14px 0;
    }
    .About .socialIcons {
        margin: 0.83em 0;
        margin-bottom: 5em;
    }
  }
  
.nav{
    overflow: hidden;
}
#m-home{
    position: fixed;
    top: 0px;
    left: 0px;
    padding: 25px;
    z-index: 10;
    cursor: pointer;
}
#m-about{
    position: fixed;
    top: 0px;
    right: 0px;
    padding: 25px;
    cursor: pointer;
    z-index: 12;
}
#m-portfolio{
    position: fixed;
    bottom: 0px;
    left: 0px;
    padding: 25px;
    cursor: pointer;
    z-index: 10;
}
#m-art{
    position: fixed;
    bottom: 0px;
    right: 0px;
    padding: 25px;
    cursor: pointer;
    z-index: 10;
}
body,#root{
  height: 100vh;
  width: 100%;
}

.Home {
  text-align: center;
  flex-direction: column;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hooks-main > svg {
  display: none;
}

.hooks-main > div {
  position: absolute;
  will-change: transform;
  border-radius: 50%;
  background: gold;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  opacity: 0.6;
}

.hooks-main > div:nth-child(1) {
  width: 120px;
  height: 120px;
}

.hooks-main > div:nth-child(2) {
  width: 250px;
  height: 250px;
}

.hooks-main > div:nth-child(3) {
  width: 150px;
  height: 150px;
}

.hooks-main > div::after {
  content: '';
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
}

.hooks-main > div:nth-child(2)::after {
  top: 70px;
  left: 70px;
  width: 70px;
  height: 70px;
}

.hooks-main > div:nth-child(3)::after {
  top: 50px;
  left: 50px;
  width: 50px;
  height: 50px;
}

.hooks-main {
  position: absolute;
  width: 100vw;
  height: 100vh;
  -webkit-filter: blur(14px);
          filter: blur(14px);
  -webkit-filter: url('#goo');
          filter: url('#goo');
  overflow: hidden;
  top: 0;
  left: 0;
}

.Hamburger .sandbox{
    background: aquamarine;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: box-shadow 0.5s, opacity 0.5s;
}
.Hamburger .projectRow{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
	margin: 15px 0;
	margin-bottom: 50px;
}
.Hamburger .projectRow p{
   text-align: left;
   font-family: 'Source Code Pro', monospace;
   color: black;
}
.Hamburger .projectRow img{
    width: 15vw;
    margin: 15px;
}
.Hamburger .aprojectRowfull{
    width: 100vw;
    margin: 15px 0;
}
.Hamburger .projectRow h2{
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 600;
}
.Hamburger .projectTags{
    font-family: 'Source Code Pro', monospace;
    color: black;
    text-decoration: none;
    font-weight: 400;
}

.Hamburger .tryme{
    position: relative;
    bottom: -35px;
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 500;
}
.Hamburger .sandbox .menu-toggle {
	height: 32px;
	position: relative;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
  margin: 90px auto;
	width: 40px;
}
.Hamburger .sandbox .menu-toggle:hover {
	color: #999;
}
.Hamburger .sandbox .menu-toggle i,
.Hamburger .sandbox .menu-toggle i:after,
.Hamburger .sandbox .menu-toggle i:before {
	display: block;
	position: absolute;
	height: 3px;
	width: 40px;
	right: 0;
	background: #999;
	border-radius: 2px;
	transform: rotate(0deg);
	transition: .25s ease-in-out;
}
.Hamburger .sandbox .menu-toggle i:after,
.Hamburger .sandbox .menu-toggle i:before {
	content: '';
}
.Hamburger .sandbox .menu-toggle i:after {
	top: -32px;
}
.Hamburger .sandbox .menu-toggle i:before {
	top: -16px;
}
.Hamburger .sandbox .menu-toggle i {
	top: 32px;
}
.Hamburger .sandbox .menu-toggle.open i:after {
	transform: rotate(45deg);
	top: -22px;
}
.Hamburger .sandbox .menu-toggle.open i:before {
	transform: rotate(-45deg);
	top: -22px;
}


.sandbox .menu-toggle.second i,
.sandbox .menu-toggle.second i:after,
.sandbox .menu-toggle.second i:before {
  width: 40px;
  border-radius: 0px;
}
.sandbox .menu-toggle.second i:nth-child(1):after {
	top: 14px;
}
.sandbox .menu-toggle.second i:nth-child(1):before {
	top: -14px;
}
.sandbox .menu-toggle.second i:nth-child(1) {
	top: 14px;
  right: 0;
}
.sandbox .menu-toggle.second i:nth-child(2):after {
	top: 14px;
}
.sandbox .menu-toggle.second i:nth-child(2):before {
	top: 14px;
}
.sandbox .menu-toggle.second i:nth-child(2) {
	top: 14px;
}
.sandbox .menu-toggle.second.open i:nth-child(1) {
	right: 25px;
}
.sandbox .menu-toggle.second.open i:nth-child(2) {
	right: -25px;
}
.sandbox .menu-toggle.second.open i:before {
	top:0px
}
.sandbox .menu-toggle.second.open i:after {
	top:0px
}
.sandbox .menu-toggle.second.open i:nth-child(1):before {
	transform: rotate(45deg);
  right: -25px;
}
.sandbox .menu-toggle.second.open i:nth-child(1):after {
	transform: rotate(-45deg);
  right: -25px;
}
.sandbox .menu-toggle.second.open i:nth-child(2):after {
	transform: rotate(45deg);
  right: 25px;
}
.sandbox .menu-toggle.second.open i:nth-child(2):before {
	transform: rotate(-45deg);
  right: 25px;
}
@media only screen and (max-width: 1200px) and (-webkit-min-device-pixel-ratio: 3),(max-width: 1000px) {
	.Hamburger .projectRow div {
		width: 70%;
		margin-bottom: 15px;
	}
}
.Netread .sandbox{
    background: aquamarine;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: box-shadow 0.5s, opacity 0.5s;
}
.Netread .projectRow{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 15px 0;
}
.Netread .projectRow p{
   text-align: left;
   font-family: 'Source Code Pro', monospace;
   color: black;
}
.Netread .projectRow img{
    width: 15vw;
    margin: 15px;
}
.Netread .projectRowfull{
    margin: 15px 0;
}
.Netread .projectRow h2{
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 700;
}
.Netread .projectTags{
    font-family: 'Source Code Pro', monospace;
    color: black;
    text-decoration: none;
    font-weight: 400;
}

.Netread  .tryme{
    position: relative;
    bottom: -35px;
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 500;
}
.Netread .twoColumns{
	display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}
.Netread .threeColumns{
    display: flex;
    width: 50%;
    align-items: flex-start;
    justify-content: space-evenly;
}
.Netread .twoColP{
    display: flex;
    width: 50%;
    align-items: flex-start;
    justify-content: space-evenly;
}
.Netread .prodThree{
    display: flex;
}
@media only screen and (max-width: 1200px) and (-webkit-min-device-pixel-ratio: 3),(max-width: 1000px){
    .Netread .twoColumns {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }
    .Netread .threeColumns{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
        flex-direction: column;
    }
    .Netread .twoColP{
        display: flex;
        width: 70%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .Netread .prodThree {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
    }
    .Netread .projectRow p {
        text-align: left;
        font-family: 'Source Code Pro', monospace;
        color: black;
        width: 70%!important;
    }
    .Netread .projectRow img {
        width: 70%!important;
    }
  }
  
.ProjectCard{
    height: 100%;
}
.ProjectCard .sandbox{
    background: aquamarine;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: box-shadow 0.5s, opacity 0.5s;
}
.ProjectCard .projectRow{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 15px 0;
}
.ProjectCard .projectRow p{
   text-align: left;
   font-family: 'Source Code Pro', monospace;
   color: black;
}
.ProjectCard .projectRow img{
    width: 15vw;
    margin: 15px;
}
.ProjectCard .aprojectRowfull{
    width: 100vw;
	margin-top: 15px ;
	flex:2 1
}
.ProjectCard .projectRow h2{
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 600;
}
.ProjectCard .projectTags{
    font-family: 'Source Code Pro', monospace;
    color: black;
    text-decoration: none;
    font-weight: 400;
}

.ProjectCard .tryme{
    position: relative;
    bottom: -35px;
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 500;
}
.Sara{
    width: 100%;
}
.Sara .sandbox{
    background: #fffff3;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: box-shadow 0.5s, opacity 0.5s;
}
.Sara .projectRow{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 15px 0;
}
.Sara .projectRow p{
   text-align: left;
   font-family: 'Source Code Pro', monospace;
   color: black;
}
.Sara .projectRow img{
    width: 15vw;
    margin: 15px;
    flex: 1 1;
}
.Sara .projectRowfull{
    margin: 15px 0;
}
.Sara .projectRow h2{
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 700;
}
.Sara .projectTags{
    font-family: 'Source Code Pro', monospace;
    color: black;
    text-decoration: none;
    font-weight: 400;
}

.Sara  .tryme{
    position: relative;
    bottom: -35px;
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 500;
}
.Sara .twoColumns{
	display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}
@media only screen and (max-width: 1200px) and (-webkit-min-device-pixel-ratio: 3),(max-width: 1000px){
    .Sara .twoColumns {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }
    .Sara .projectRow p {
        text-align: left;
        font-family: 'Source Code Pro', monospace;
        color: black;
        width: 70%!important;
    }
    .Sara .projectRow img {
        width: 70%!important;
    }
  } 
.ProjectManagement .sandbox{
    background: #efeff0;
    width: 100%;
    height:      100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: box-shadow 0.5s, opacity 0.5s;
}
.ProjectManagement .projectRow{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 15px 0;
}
.ProjectManagement .projectRow p{
   text-align: left;
   font-family: 'Source Code Pro', monospace;
   color: black;
}
.ProjectManagement .projectRow img{
    width: 15vw;
    margin: 15px;
}
.ProjectManagement .aprojectRowfull{
    width: 100vw;
	margin-top: 15px ;
	flex:2 1
}
.ProjectManagement .projectRow h2{
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 600;
}
.ProjectManagement .projectTags{
    font-family: 'Source Code Pro', monospace;
    color: black;
    text-decoration: none;
    font-weight: 400;
}

.ProjectManagement .tryme{
    position: relative;
    bottom: -35px;
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 500;
}
.Multitrack{
    width: 100%;
    width: 100%;
}
.Multitrack .sandbox{
    background: #4070f4;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: box-shadow 0.5s, opacity 0.5s;
}
.Multitrack .projectRow{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 15px 0;
    padding:0 15px;
}
.Multitrack .projectRow p{
   text-align: left;
   font-family: 'Source Code Pro', monospace;
   color: black;
}
.Multitrack .projectRow img{
    width: 20vw;
    margin: 15px;
    flex: 1 1;
}
.Multitrack .aprojectRowfull{
    margin: 15px 0;
    width: 100%;
}
.Multitrack .projectRow h2{
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 700;
    align-self: center;
}
.Multitrack .projectTags{
    font-family: 'Source Code Pro', monospace;
    color: black;
    text-decoration: none;
    font-weight: 400;
}

.Multitrack  .tryme{
    position: relative;
    bottom: -35px;
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 500;
}
.Multitrack .twoColumns{
	display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}
@media only screen and (max-width:1200px) and (-webkit-min-device-pixel-ratio: 3),(max-width: 1000px){
    .Multitrack .twoColumns {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }
    .Multitrack .projectRow p {
        text-align: left;
        font-family: 'Source Code Pro', monospace;
        color: black;
        width: 70%!important;
    }
    .Multitrack .projectRow img {
        width: 70%!important;
    }
  }
* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial,
    sans-serif;
  background: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.paintSingleCard{
  max-height:50vh;
  max-width:70vw;
  pointer-events: none;
}
.cardena{
  margin-top: 15px;
  margin-bottom: 15px;
}
.cardena > div {
    position: relative;
    background: white;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: box-shadow 0.5s, opacity 0.5s;
    will-change: transform;
    border: 5px solid white;
    cursor: -webkit-grab;
    cursor: grab;
    overflow: hidden;
    touch-action: none;
  }
  
  .cardena > div > div {
    will-change: transform;
    height: 100%;
    margin: 0vw 0;
  }
  
  .cardena > div > div > * {
    height: 100%;
    background-size: cover;
    background-position: center center;
    margin: 0vw 0;
  }
  
  .cardena > div.dragging {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  
  .cardena > div.disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  .cardena > div:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  
  button {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  

#root {
    overflow: auto;
    display: flex;
    justify-content: center;
    background: #f0f0f0;
    padding: 15px;
  }
  .subContain{
    position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 2000px;
        background: white;
        transition: .5s all ease-in-out;
        z-index: 8;
  }
  .subContain.open{
        top: 0;
  }
  .list {
    position: relative;
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto,
      segoe ui, arial, sans-serif;
    width: 100%;
    height: 100%;
    overflow: hidden;
        padding: 20px 0;
  }
  
  .list > div.item {
    position: absolute;
    will-change: transform, width, height, opacity;
    padding: 15px;
  }
  
  .list > div.item > div.card {
    position: relative;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 10px;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: .3s all ease-in-out;
  }
  .list > div > div.hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  
  .ArtContainer{
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    height:100%;
  }
  .ArtContainer span span {
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 500;
  }
  .ArtContainer .infoRow{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }
  .ArtContainer .infoRow .infoTitle p {
    margin: 5px 0;
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 500;
  }
  .ArtContainer .infoRow .infoCont p {
    margin: 5px 0;
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 500;
  }
  .ArtContainer .infoRow .infoTitle{
    text-align: right;
    flex: 1 1;
  }
  .ArtContainer .infoRow .infoCont{
    text-align: left;
    flex: 1 1;
    }
.Lab{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Lab .head{
    flex: 1 1;
}
.Lab .wrap{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.Lab .wrap .item{
    padding: 10px;
    margin:5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Lab .item span {
    font-family: monospace;
}
.folder{
    margin: 5px;
    cursor: pointer;
}
.folder .aaa{
    background-color: black;
    height: 25px;
    width: 40px;
    border-radius: 10px;
    position: relative;
    top: 15px;

}
.folder .bbb{
    background-color: black;
    height: 60px;
    width: 80px;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: .3s all ease-in-out;
}
.folder .bbb.hover{
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
body,#root{
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

.App {
  text-align: center;
  flex-direction: column;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

