@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400;1,500;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&family=Source+Code+Pro:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400;1,500;1,600;1,700;1,900&display=swap');
body {
  margin: 0;
  font-family: 'Source Code Pro', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: 'Source Code Pro', monospace;
}
/*Fonts*/
.link {
  font-family: 'Source Code Pro', monospace;
  color: black;
  font-weight: 700;
  text-decoration: underline;
}
h1,h2{
  font-family: 'Source Code Pro', monospace;
  color: black;
  text-decoration: none;
  font-weight: 400;
}
span{
  font-family: 'Source Code Pro', monospace;
}