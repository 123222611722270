
#root {
    overflow: auto;
    display: flex;
    justify-content: center;
    background: #f0f0f0;
    padding: 15px;
  }
  .subContain{
    position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 2000px;
        background: white;
        transition: .5s all ease-in-out;
        z-index: 8;
  }
  .subContain.open{
        top: 0;
  }
  .list {
    position: relative;
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto,
      segoe ui, arial, sans-serif;
    width: 100%;
    height: 100%;
    overflow: hidden;
        padding: 20px 0;
  }
  
  .list > div.item {
    position: absolute;
    will-change: transform, width, height, opacity;
    padding: 15px;
  }
  
  .list > div.item > div.card {
    position: relative;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 10px;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: .3s all ease-in-out;
  }
  .list > div > div.hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  
  .ArtContainer{
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    height:100%;
  }
  .ArtContainer span span {
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 500;
  }
  .ArtContainer .infoRow{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }
  .ArtContainer .infoRow .infoTitle p {
    margin: 5px 0;
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 500;
  }
  .ArtContainer .infoRow .infoCont p {
    margin: 5px 0;
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 500;
  }
  .ArtContainer .infoRow .infoTitle{
    text-align: right;
    flex: 1 1 0%;
  }
  .ArtContainer .infoRow .infoCont{
    text-align: left;
    flex: 1 1 0%;
    }