.ProjectCard{
    height: 100%;
}
.ProjectCard .sandbox{
    background: aquamarine;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: box-shadow 0.5s, opacity 0.5s;
}
.ProjectCard .projectRow{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 15px 0;
}
.ProjectCard .projectRow p{
   text-align: left;
   font-family: 'Source Code Pro', monospace;
   color: black;
}
.ProjectCard .projectRow img{
    width: 15vw;
    margin: 15px;
}
.ProjectCard .aprojectRowfull{
    width: 100vw;
	margin-top: 15px ;
	flex:2
}
.ProjectCard .projectRow h2{
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 600;
}
.ProjectCard .projectTags{
    font-family: 'Source Code Pro', monospace;
    color: black;
    text-decoration: none;
    font-weight: 400;
}

.ProjectCard .tryme{
    position: relative;
    bottom: -35px;
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 500;
}