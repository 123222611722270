body,#root{
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

.App {
  text-align: center;
  flex-direction: column;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}
