.nav{
    overflow: hidden;
}
#m-home{
    position: fixed;
    top: 0px;
    left: 0px;
    padding: 25px;
    z-index: 10;
    cursor: pointer;
}
#m-about{
    position: fixed;
    top: 0px;
    right: 0px;
    padding: 25px;
    cursor: pointer;
    z-index: 12;
}
#m-portfolio{
    position: fixed;
    bottom: 0px;
    left: 0px;
    padding: 25px;
    cursor: pointer;
    z-index: 10;
}
#m-art{
    position: fixed;
    bottom: 0px;
    right: 0px;
    padding: 25px;
    cursor: pointer;
    z-index: 10;
}