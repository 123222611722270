.Multitrack{
    width: 100%;
    width: 100%;
}
.Multitrack .sandbox{
    background: #4070f4;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: box-shadow 0.5s, opacity 0.5s;
}
.Multitrack .projectRow{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 15px 0;
    padding:0 15px;
}
.Multitrack .projectRow p{
   text-align: left;
   font-family: 'Source Code Pro', monospace;
   color: black;
}
.Multitrack .projectRow img{
    width: 20vw;
    margin: 15px;
    flex: 1;
}
.Multitrack .aprojectRowfull{
    margin: 15px 0;
    width: 100%;
}
.Multitrack .projectRow h2{
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 700;
    align-self: center;
}
.Multitrack .projectTags{
    font-family: 'Source Code Pro', monospace;
    color: black;
    text-decoration: none;
    font-weight: 400;
}

.Multitrack  .tryme{
    position: relative;
    bottom: -35px;
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 500;
}
.Multitrack .twoColumns{
	display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}
@media only screen and (max-width:1200px) and (-webkit-min-device-pixel-ratio: 3),(max-width: 1000px){
    .Multitrack .twoColumns {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }
    .Multitrack .projectRow p {
        text-align: left;
        font-family: 'Source Code Pro', monospace;
        color: black;
        width: 70%!important;
    }
    .Multitrack .projectRow img {
        width: 70%!important;
    }
  }