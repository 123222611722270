.Hamburger .sandbox{
    background: aquamarine;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: box-shadow 0.5s, opacity 0.5s;
}
.Hamburger .projectRow{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
	margin: 15px 0;
	margin-bottom: 50px;
}
.Hamburger .projectRow p{
   text-align: left;
   font-family: 'Source Code Pro', monospace;
   color: black;
}
.Hamburger .projectRow img{
    width: 15vw;
    margin: 15px;
}
.Hamburger .aprojectRowfull{
    width: 100vw;
    margin: 15px 0;
}
.Hamburger .projectRow h2{
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 600;
}
.Hamburger .projectTags{
    font-family: 'Source Code Pro', monospace;
    color: black;
    text-decoration: none;
    font-weight: 400;
}

.Hamburger .tryme{
    position: relative;
    bottom: -35px;
    font-family: 'Source Code Pro', monospace;
    color: black;
    font-weight: 500;
}
.Hamburger .sandbox .menu-toggle {
	height: 32px;
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
  margin: 90px auto;
	width: 40px;
}
.Hamburger .sandbox .menu-toggle:hover {
	color: #999;
}
.Hamburger .sandbox .menu-toggle i,
.Hamburger .sandbox .menu-toggle i:after,
.Hamburger .sandbox .menu-toggle i:before {
	display: block;
	position: absolute;
	height: 3px;
	width: 40px;
	right: 0;
	background: #999;
	border-radius: 2px;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .25s ease-in-out;
	-moz-transition: .25s ease-in-out;
	-o-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
}
.Hamburger .sandbox .menu-toggle i:after,
.Hamburger .sandbox .menu-toggle i:before {
	content: '';
}
.Hamburger .sandbox .menu-toggle i:after {
	top: -32px;
}
.Hamburger .sandbox .menu-toggle i:before {
	top: -16px;
}
.Hamburger .sandbox .menu-toggle i {
	top: 32px;
}
.Hamburger .sandbox .menu-toggle.open i:after {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	top: -22px;
}
.Hamburger .sandbox .menu-toggle.open i:before {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
	top: -22px;
}


.sandbox .menu-toggle.second i,
.sandbox .menu-toggle.second i:after,
.sandbox .menu-toggle.second i:before {
  width: 40px;
  border-radius: 0px;
}
.sandbox .menu-toggle.second i:nth-child(1):after {
	top: 14px;
}
.sandbox .menu-toggle.second i:nth-child(1):before {
	top: -14px;
}
.sandbox .menu-toggle.second i:nth-child(1) {
	top: 14px;
  right: 0;
}
.sandbox .menu-toggle.second i:nth-child(2):after {
	top: 14px;
}
.sandbox .menu-toggle.second i:nth-child(2):before {
	top: 14px;
}
.sandbox .menu-toggle.second i:nth-child(2) {
	top: 14px;
}
.sandbox .menu-toggle.second.open i:nth-child(1) {
	right: 25px;
}
.sandbox .menu-toggle.second.open i:nth-child(2) {
	right: -25px;
}
.sandbox .menu-toggle.second.open i:before {
	top:0px
}
.sandbox .menu-toggle.second.open i:after {
	top:0px
}
.sandbox .menu-toggle.second.open i:nth-child(1):before {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
  right: -25px;
}
.sandbox .menu-toggle.second.open i:nth-child(1):after {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
  right: -25px;
}
.sandbox .menu-toggle.second.open i:nth-child(2):after {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
  right: 25px;
}
.sandbox .menu-toggle.second.open i:nth-child(2):before {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
  right: 25px;
}
@media only screen and (max-width: 1200px) and (-webkit-min-device-pixel-ratio: 3),(max-width: 1000px) {
	.Hamburger .projectRow div {
		width: 70%;
		margin-bottom: 15px;
	}
}